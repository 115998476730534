@use 'sass:math';
@import 'styles/theme';

.product-header-slider-ratio {
  padding-bottom: math.div(1, math.div(3, 4)) * 100%;
  @include from('lg') {
    padding-bottom: 0;
  }
}

.product-header-bundle-ratio {
  padding-bottom: 100%;
}
