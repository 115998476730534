@use 'sass:math';
@import 'styles/theme';

$rightItemWidthNormal: 165px;
$rightItemWidthMenu: 150px;

.PushNotification {
  display: flex;
  flex-direction: row;
  min-height: $rightItemWidthNormal;

  .link {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: $rightItemWidthNormal;

    @include from('md') {
      border-radius: theme($borderRadius, 'default');
      overflow: hidden;
    }
  }

  @include from('md') {
    border-radius: theme($borderRadius, 'default');
    overflow: hidden;
  }

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
    @include background-opacity(theme($colors, 'shade-1'), 0.3);

    .leftContent {
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'padding',
          'gutters': 1,
          'gaps': 0,
          'columns': 0,
        )
      );

      display: flex;
      height: 100%;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;

      .tags {
        display: inline-flex;
        margin-bottom: theme($spacings, 8);
      }

      .title {
        margin-bottom: theme($spacings, 16);
      }
    }
  }

  .right {
    flex-grow: 0;
    width: $rightItemWidthNormal;
  }

  // Variation
  &.isMenuDesktop {
    min-height: $rightItemWidthMenu;

    .right {
      width: $rightItemWidthMenu;
      order: 1;
    }

    .left {
      order: 2;
      @include background-opacity(theme($colors, 'shade-1'), 0.1);
    }

    .leftContent {
      width: min-content;
    }

    .cta {
      width: max-content;
    }
  }
}
