@import 'styles/theme';

.PlayIcon,
.PauseIcon,
.SoundIcon,
.MutedIcon {
  width: 40px;
  height: 40px;
}

.ArrowIcon,
.CartIcon,
.BurgerIcon,
.SearchIcon,
.AccountIcon,
.CloseIcon {
  width: 32px;
  height: 32px;
}

.PhoneIcon,
.MailIcon,
.ClosePopinIcon,
.CheckIcon,
.FacebookIcon,
.InstagramIcon {
  width: 16px;
  height: 16px;
}

.SmallLayoutIcon,
.BigLayoutIcon,
.TrashIcon {
  height: 24px;
  width: 24px;
}

.LinkIcon {
  height: 16px;
  width: 16px;
}

.TickedIcon {
  width: 14px;
}

.PlusIcon {
  width: 10px;
}
.MinusIcon {
  width: 10px;
}

.HeartEmptyIcon {
  width: 16px;

  path {
    @include set-transition(
      (
        'fill': 'default',
      )
    );
  }
}

.TextEdit {
  width: 13px;
}

.LeftIcon,
.RightIcon,
.BottomIcon,
.TopIcon {
  width: 4px;

  path {
    @include set-transition(
      (
        'fill': 'default',
      )
    );
  }
}

.TopIcon {
  transform: rotateZ(90deg);
}

.BottomIcon {
  transform: rotateZ(-90deg);
}

.RightIcon {
  transform: rotateZ(180deg);
}

.QuestionMarkIcon {
  width: 8px;

  path {
    @include set-transition(
      (
        'fill': 'default',
      )
    );
  }
}

.CartIcon {
  path {
    @include set-transition(
      (
        'stroke': 'default',
      )
    );
  }
}
