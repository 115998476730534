@import 'styles/theme';

.DynamicCartIcon {
  position: relative;
  display: inline-flex;

  .icon {
    width: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: theme($colors, 'error-compare-at-price');
    width: 6px;
    height: 6px;
    transform: translate3d(50%, 70%, 0px) scale(0);
    display: block;
    border-radius: 50%;

    @include set-transition(
      (
        'transform': 'reduced',
      )
    );
  }

  &.withArticles {
    &::after {
      transform: translate3d(50%, 70%, 0px) scale(1);
    }
  }
}
