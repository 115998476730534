@import 'styles/theme';

.rich-text {
  &-banner {
    font-family: theme($fontFamilies, 'Primary');
    font-weight: bold;
    text-transform: uppercase;

    @include font-base(11, 12, 'Primary', 1.1, 0.1em, 'bold');
  }

  &-editorial {
    // Fonts display
    h2 {
      @include font-base(28, 36, 'Primary', 1.1, 0, 'regular');
    }

    h3 {
      @include font-base(18, 18, 'Primary', 1.27, 0.15em, 'bold');
      @include font-upper();
    }

    h4 {
      @include font-upper();
      @include font-base(14, 14, 'Primary', 1.5, 0.15em, 'bold');
    }

    p {
      @include font-base(18, 18, 'Secondary', 1.3, 0, 'regular');
    }

    b {
      @include font-base(18, 18, 'Secondary', 1.3, 0, 'medium');
    }

    // Alignment

    // Spacings
    h2 {
      & + h3,
      & + p,
      & + ul {
        margin-top: theme($spacings, 56);
      }
    }

    h3 {
      & + h4,
      & + p,
      & + ul {
        margin-top: theme($spacings, 16);
      }
    }

    h4 {
      & + p,
      & + ul {
        margin-top: theme($spacings, 16);
      }
    }

    p,
    ul {
      & + h2,
      & + h3 {
        margin-top: theme($spacings, 56);
      }

      & + p,
      & + ul {
        margin-top: theme($spacings, 16);
      }
    }

    // Styling
    ul {
      li {
        position: relative;
        display: flex;

        &:before {
          content: '\2022';
          margin-right: theme($spacings, 5);
        }

        & + li {
          margin-top: theme($spacings, 8);
        }
      }
    }

    // Globals
    :global(.centered) {
      display: block;
      text-align: center;
    }

    :global(.inlineLink) {
      text-decoration: underline;
    }

    :global(.ctaWrapper) {
      margin-top: theme($spacings, 24);

      & + * {
        margin-top: theme($spacings, 24);
      }
    }

    :global(.captionImageWrapper) {
      margin-top: theme($spacings, 24);

      & + * {
        margin-top: theme($spacings, 24);
      }
    }
  }
}
