@import 'styles/theme';

.BrandLogo {
  display: inline-flex;

  .logo {
    width: 117px;
    height: 20px;

    path {
      @include set-transition(
        (
          'fill': 'default',
        )
      );
    }
  }

  .hiddenTitle {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
  }

  .link {
    display: inline-flex;
  }

  &.blackTheme {
    path {
      fill: theme($colors, 'black');
    }
  }

  &.whiteTheme {
    path {
      fill: theme($colors, 'white');
    }
  }
}
