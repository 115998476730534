@import 'styles/theme';

.FooterColumn {
  .title + ul {
    margin-top: theme($spacings, 12);
  }

  li {
    + li {
      margin-top: theme($spacings, 4);
    }
  }
}
