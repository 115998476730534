@import 'styles/theme';

.Checkbox {
  position: relative;

  &:hover {
    .checkmark {
      @include background-opacity(theme($colors, 'shade-1'), 0.5);
    }
  }

  .checkmark {
    @include background-opacity(theme($colors, 'shade-1'), 0.24);
    border-radius: theme($borderRadius, 'small');
    height: 18px;
    width: 18px;
    border: none;
    @include set-transition(
      (
        'background-color': 'reduced',
      )
    );
  }

  .checkIcon {
    width: 75%;
  }

  .checkmarkActive {
    background: unset;
    &.error {
      background-color: red;
    }
  }

  .content {
    align-items: flex-start;
  }

  .label {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 0.18em;
  }

  .errorList {
    margin-top: theme($spacings, 5);
  }

  &.isSwitch {
    .content {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 24px;
    }

    .checkmark {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: 1px solid theme($colors, 'axolotl');
      transition: 0.4s;
      border-radius: 40px;
      opacity: 0.5;
    }

    .checkmark:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 1px;
      border-radius: 50%;
      // background-color: theme($colors, 'axolotl');

      @include set-transition(
        (
          'transform': 'default',
        )
      );
    }

    .checkmarkActive {
      display: none;
    }

    input:checked + div .checkmark {
      opacity: 1;
    }

    input:checked + div .checkmark:before {
      transform: translate3d(18px, 0, 0);
    }
  }
}
