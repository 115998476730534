@import 'styles/theme';

@mixin font-medium {
  font-weight: theme($fontWeights, 'medium');
}

@mixin font-bold {
  font-weight: theme($fontWeights, 'bold');
}

@mixin font-upper {
  text-transform: uppercase;
}

@mixin font-transform-none {
  text-transform: none;
}

@mixin font-spaced($value: 0.2em) {
  letter-spacing: $value;
}

@mixin font-primary {
  font-family: theme($fontFamilies, 'Primary');
  @include font-bold();
  @include font-upper();
  @include font-spaced();
}

@mixin font-primary-pro {
  font-family: theme($fontFamilies, 'Secondary');
}

@mixin font-base(
  $mobileSize,
  $desktopSize,
  $family,
  $lineHeight,
  $letterSpacing: 0,
  $weight: 'normal',
  $style: null
) {
  font-family: theme($fontFamilies, $family);
  font-size: theme($fontSizes, $mobileSize);
  font-weight: theme($fontWeights, $weight);
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;

  @if $desktopSize != null {
    @include from('md') {
      font-size: theme($fontSizes, $desktopSize);
    }
  }

  @if $style != null {
    font-style: $style;
  }
}

@mixin font-fluid-base(
  $mobileSize,
  $desktopSize,
  $family,
  $lineHeight,
  $letterSpacing: 0,
  $weight: 'normal',
  $style: null
) {
  font-family: theme($fontFamilies, $family);
  font-weight: theme($fontWeights, $weight);
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;

  @if $style != null {
    font-style: $style;
  }

  @include fluidFontSizeType(
    theme($screens, 'md'),
    theme($max, 'width-design'),
    theme($fluidFontSizes, $mobileSize),
    theme($fluidFontSizes, $desktopSize)
  );
}
