@import 'styles/theme';

.ErrorList {
  text-align: right;

  div {
    @include background-opacity(theme($colors, 'error-compare-at-price'), 0.1);
    display: inline-flex;
    padding: theme($spacings, 3) theme($spacings, 6) theme($spacings, 2);
    min-height: theme($spacings, 16);
    align-items: center;
    box-sizing: border-box;
    border-radius: theme($borderRadius, 'default');
    color: theme($colors, 'error-compare-at-price');
    backdrop-filter: blur(10px);
  }
}
