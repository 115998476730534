@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.label {
  /**
  * Brown STD
  */
  &-10-primary {
    @include font-base(12, 12, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-12-primary {
    @include font-base(14, 14, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-18-primary-reg {
    @include font-base(18, 18, 'Secondary', 1.27, 0.1em, 'medium');
  }

  &-16-primary {
    @include font-base(18, 18, 'Primary', 1.1, 0.1em, 'bold');
  }

  &-18-primary {
    @include font-base(18, 18, 'Primary', 1.1, 0.1em, 'bold');
  }

  /**
  * Source Code Pro
  */
  &-8-secondary {
    @include font-base(10, 10, 'Secondary', 1.1, 0.1em, 'medium');
  }

  &-10-secondary {
    @include font-base(12, 12, 'Secondary', 1.1, 0.1em, 'medium');
  }

  &-11-secondary {
    @include font-base(13, 13, 'Secondary', 1.1, 0.1em, 'medium');
  }

  &-12-secondary {
    @include font-base(14, 14, 'Secondary', 1.1, 0.01em, 'medium');
  }

  &-12-10-secondary {
    @include font-base(14, 12, 'Secondary', 1.1, 0.1, 'normal');
  }
  &-bch-12-10-secondary {
    @include font-base(14, 18, 'Secondary', 1.1, 0.1, 'normal');
  }

  &-bch-12-10-secondary {
    @include font-base(14, 18, 'Secondary', 1.1, 0.1, 'normal');
  }

  &-18-secondary {
    @include font-base(18, 18, 'Secondary', 1.1, 0.1, 'normal');
  }
}
