@import 'styles/theme';

.Navigation {
  pointer-events: none;
  @include switchDesktopMobileClasses('lg', flex);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .banner {
    z-index: 1;
  }

  .mobileNavigation {
    pointer-events: auto;
  }

  .logo path {
    @include set-transition(
      (
        'fill': 'default',
      )
    );
  }

  &.pampas {
    .logo path {
      fill: theme($colors, 'pampas');
    }
  }

  &.axolotl {
    .logo path {
      fill: theme($colors, 'axolotl');
    }
  }
}

.NavigationScrollRef {
  visibility: hidden;
  position: absolute;
  top: theme($spacings, 50);
  width: 100%;
  height: 1px;
}
