@import 'styles/theme';

.PopInManager {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  box-sizing: border-box;

  &.vertical-top {
    align-items: flex-start;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.horizontal-left {
    justify-content: flex-start;
  }

  &.horizontal-center {
    justify-content: center;
  }

  &.horizontal-right {
    justify-content: flex-end;
  }

  &.show {
    .background {
      opacity: 1;
    }
  }

  > * {
    pointer-events: auto;
  }

  .background {
    display: none;

    @include from('l') {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(theme($colors, 'black'), 0.15);
    }
  }

  .PopInManagerWrapper {
    height: 100%;
    width: 100%;

    @include from('l') {
      display: flex;
      width: auto;
      justify-content: center;
      align-items: center;
      height: inherit;
    }
  }
}
