@import 'styles/theme';
@import 'styles/text-presets/typography.module';

.form {
  .fieldContainer {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    text-orientation: sideways-right;
  }

  // Text like input style
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  select {
    box-sizing: border-box;
    width: 100%;
    @include font-base(14, 14, 'Secondary', auto, 0, 'regular');
    background-color: theme($colors, 'shade-3');
    border-radius: theme($borderRadius, 'default');
    padding: 26px 24px 13px 24px;
    outline: none;
    border: 1px solid theme($colors, 'shade-3');

    &[aria-invalid='true'],
    &:invalid {
      border: 1px solid theme($colors, 'error-compare-at-price');
    }
  }

  $phone-input-length: 80%;
  $phone-input-offset: 2px;

  select[name='phoneCountry'] ~ div {
    position: relative;
    display: inline-block;
    width: calc($phone-input-length + $phone-input-offset);

    vertical-align: middle;
    display: inline-block;
  }

  input[type='tel'] {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 27px 24px 14px 24px;
  }

  select[name='phoneCountry'] {
    display: inline-block;
    width: calc(100% - $phone-input-length - $phone-input-offset);
    text-overflow: ellipsis;
    vertical-align: middle;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 23px 14px;
    height: 100%;

    // This one is for fucking Safari always behaving like he wants
    line-height: 1;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 6px;
    background-position: calc(100% - 4px) center;
    background-repeat: no-repeat;
    background-color: theme($colors, 'shade-3');
  }

  select[name='country'] {
    padding: 19px 24px;
    margin-bottom: 16px;
    appearance: none;
    cursor: pointer;
  }

  // Label of text like input style
  label:first-child {
    @include font-base(14, 14, 'Secondary', auto, 0, 'regular');
    transition: all 0.18s ease-out;
    position: absolute;
    top: 50%;
    left: 24px;
    transform-origin: left;
    transform: translateY(-50%);
  }

  // Label specifity for country
  label[for='country'] {
    transform: scale(0.86) translateY(-195%);
    opacity: 0.5;
  }

  // label of text like input style
  label[data-input-focused='true'],
  label[data-input-has-placeholder='true'],
  label[data-input-has-value='true'] {
    transform: scale(0.86) translateY(-120%);
    opacity: 0.5;
  }

  label[for='phone'][data-input-focused='true'],
  label[for='phone'][data-input-has-placeholder='true'],
  label[for='phone'][data-input-has-value='true'] {
    transform: scale(0.86) translateY(-120%);
    opacity: 0.5;
  }

  // Label of checkbox / radio style
  label:nth-child(2) {
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    @include font-base(12, 12, 'Primary', 1.27, 0.1em, 'bold');
  }

  span[role='alert'] {
    @include font-base(12, 12, 'Secondary', auto, 0, 'regular');
    color: theme($colors, 'error-compare-at-price');
    display: block;
    margin-top: 4px;
  }
}
