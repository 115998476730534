@import 'styles/theme';

.PanelManager {
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  width: vw(100);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;

  &.enterFromTop {
    z-index: 9;
  }

  @include from('lg') {
    top: 0;
    height: 100%;
    width: 100%;
  }

  .closeIcon {
    position: fixed;
    top: getBannerHeight(theme($spacings, 5));
    right: 0;
    z-index: 30;
    display: inline-flex;
    align-items: center;
    pointer-events: auto;
    display: none;

    .icon {
      position: relative;
      height: 16px;
      width: 16px;
      padding-top: theme($spacings, 15);
      padding-bottom: theme($spacings, 15);

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'padding-left',
          'gutters': 0,
          'gaps': 0,
          'columns': 1,
        )
      );
      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'padding-right',
          'gutters': 0,
          'gaps': 0,
          'columns': 1,
        )
      );
    }

    @include from('lg') {
      display: none;
    }
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &.enterFromLeft {
      align-items: flex-start;
    }

    &.enterFromTop {
      align-items: flex-start;
      flex-direction: row;
      z-index: 9;

      .content {
        height: auto;

        @include from('lg') {
          width: 100%;
        }
      }
    }
  }

  .background {
    display: none;
    pointer-events: auto;
    opacity: 0;

    @include from('lg') {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .content {
    pointer-events: auto;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: theme($colors, 'white');
    overflow: hidden;

    @include from('lg') {
      width: 600px;
    }
  }

  .children {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
