@import 'styles/theme';

.Footer {
  padding-top: theme($spacings, 32);
  padding-bottom: theme($spacings, 32);
  background-color: theme($colors, 'white');
  color: theme($colors, 'black');

  @include switchDesktopMobileClasses('md');

  .grid {
    padding-left: theme($mobile, 'gap');
    padding-right: theme($mobile, 'gap');

    @include from('md') {
      padding-left: theme($desktop, 'gutter');
      padding-right: theme($desktop, 'gutter');
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 7;

    @include from('md') {
      grid-column: 1 / 13;
    }
  }

  .isHomepage {
    visibility: hidden;
  }

  .breadcrumbContainer {
    display: flex;
    justify-content: center;
  }

  .mediumLinks {
    margin-top: theme($spacings, 48);
    margin-bottom: theme($spacings, 48);
  }

  .mediumLinks,
  .iconLinks,
  .selectLanguage {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .iconLinks {
    margin-top: 28px;
    margin-bottom: theme($spacings, 48);

    @include from('lg') {
      display: flex;
      width: 100%;
      margin-top: 52px;
      margin-bottom: theme($spacings, 56);
    }
  }

  .iconLinkListItem {
    overflow: hidden;
    border-radius: theme($borderRadius, 'default');

    & + .iconLinkListItem {
      margin-top: theme($spacings, 8);

      @include from('lg') {
        margin-top: 0;
      }
    }

    .iconLink {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: theme($spacings, 16);
      @include background-opacity(theme($colors, 'shade-1'), 0.1);

      .iconContainer {
        flex-shrink: 0;
        display: inline-block;
        box-sizing: border-box;
        padding: theme($spacings, 10);
        width: theme($spacings, 52);
        height: theme($spacings, 52);
      }

      .icon {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .iconLinkLeftRight {
        display: flex;
        align-items: center;
        flex: 1;
        padding: theme($spacings, 8);
      }

      .arrowIcon {
        flex-shrink: 0;
        margin-left: auto;

        path {
          stroke: currentColor;
        }
      }

      @include from('lg') {
        justify-content: center;
        padding: theme($spacings, 32);

        @include set-transition(
          (
            'background-color': 'default',
          )
        );

        &:hover {
          @include background-opacity(theme($colors, 'shade-1'), 0.2);
        }

        .iconContainer {
          padding: theme($spacings, 12);
          width: theme($spacings, 64);
          height: theme($spacings, 64);
        }

        .arrowIcon {
          display: none;
        }

        .iconLinkLeftRight {
          display: inline-flex;
          flex: unset;
        }
      }
    }

    @include from('lg') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 4,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .mediumLinks {
    text-align: center;

    .mediumLinkListItem {
      & + .mediumLinkListItem {
        margin-top: theme($spacings, 16);
      }
    }

    .socialLinks {
      margin-top: theme($spacings, 8);
    }

    .socialLink {
      display: inline-flex;
      padding: theme($spacings, 8);
    }

    @include from('lg') {
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex: 1;

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'padding-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );

      .mediumLinkListItem {
        display: inline-flex;
        cursor: pointer;

        & + .mediumLinkListItem {
          margin-left: theme($spacings, 32);
          margin-top: 0;
        }
      }
    }
  }

  .footerBottom {
    padding: theme($spacings, 50) 0;

    .column {
      & + .column {
        margin-top: theme($spacings, 50);

        @include from('lg') {
          margin-top: 0;
        }
      }
    }
    .informationsColumn {
      margin-top: theme($spacings, 80);

      @include from('lg') {
        margin-top: 0;
      }
    }

    .newsletter {
      margin-top: theme($spacings, 30);
    }

    .socialLinks {
      margin-top: theme($spacings, 24);
      .socialLink {
        & + .socialLink {
          margin-left: theme($spacings, 15);
        }
      }
    }

    .footerSwitcher {
      margin-top: theme($spacings, 45);
    }

    .languageSelect {
      padding-left: 0;
    }

    @include from('lg') {
      padding: 0 getGridColumnWidth(0.5, $desktop) theme($spacings, 48)
        getGridColumnWidth(0.5, $desktop);
      display: flex;

      .columnsContainer {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1;
        margin-right: theme($spacings, 60);
        gap: theme($spacings, 30) getGridColumnWidth(1, $desktop);

        .column {
          max-width: 200px;
          min-width: 100px;
          flex: 1 1;
        }
      }

      .informationsColumn {
        margin-left: auto;
        flex-shrink: 0;
        @include applyColumnWidth('width', 3, $desktop);
      }

      .newsletter {
        margin-top: theme($spacings, 14);
      }

      .socialLinks {
        margin-top: theme($spacings, 24);
      }

      .footerSwitcher {
        margin-top: theme($spacings, 40);
        display: flex;
        align-items: flex-start;

        .selectLanguage {
          display: inline-flex;
        }
      }
    }
  }

  @include from('max-grid-width') {
    padding: 0 getGridColumnWidth(0.5, $desktop) theme($spacings, 48)
      getGridColumnMaxWidth(0.5, $desktop);
  }
}
