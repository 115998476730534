@import 'styles/theme';

.Tag {
  display: inline-flex;
  padding: theme($spacings, 3) theme($spacings, 5);
  min-height: theme($spacings, 16);
  align-items: center;
  box-sizing: border-box;
  border-radius: theme($borderRadius, 'default');
  pointer-events: none;
  backdrop-filter: blur(10px);

  &.withBackground {
    &.whiteTheme {
      background: theme($colors, 'white');
    }

    &.blackTheme {
      background: theme($colors, 'black');
    }

    &.greenTheme {
      @include background-opacity(theme($colors, 'green'), 0.1);
    }

    &.shade-1Theme {
      @include background-opacity(theme($colors, 'shade-1'), 0.1);
    }
  }
}
