@import 'styles/theme';

.ToastCenter {
  pointer-events: none;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  width: vw(100);
  height: vh(100);
  z-index: 1001;

  .grid {
    position: relative;

    @include from('md') {
      max-width: theme($max, 'width');
      margin: auto;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    transform: translateZ(0px);
    will-change: opacity;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'black');
    opacity: 0;

    @include set-transition(
      (
        'opacity': 'default',
      )
    );
  }

  .Toast {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 64px;
    pointer-events: auto;
    width: 100%;
    right: 0;
    box-shadow: theme($shadows, 'small');
    background-color: theme($colors, 'white');
    padding-left: theme($spacings, 24);
    padding-right: theme($spacings, 24);
    box-sizing: border-box;

    @include from('md') {
      right: 24px;
      top: calc(theme($navHeight, 'desktop') + theme($spacings, 10));
      min-height: auto;
      width: auto;
      border-radius: theme($borderRadius, 'default');
      padding: 16px;
      box-sizing: border-box;

      &.withBanner {
        top: calc(
          theme($navHeight, 'desktop') + theme($navHeight, 'desktopBanner') +
            theme($spacings, 10)
        );
      }
    }
  }
}
