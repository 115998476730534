@import 'styles/theme';

.Image {
  width: 100%;
  height: 100%;
  position: relative;
}

.placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: theme($colors, 'shade-3');
  opacity: 0;
  pointer-events: none;

  &.asPlaceholder {
    opacity: 1;
  }
}
