@import 'styles/theme';

.InlineCta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding-bottom: theme($spacings, 4);
  overflow: hidden;

  @include set-transition(
    (
      'color': 'default',
      'border-color': 'default',
    )
  );

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.loading {
    pointer-events: none;
  }

  .isLoading {
    opacity: 0;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  &.noPadding {
    padding-bottom: 0;
  }

  .line {
    position: absolute;
    bottom: 0px;
  }
}
