@import './reset.scss';
@import './theme';

@import './cookie-pro.scss';

:root {
  --scroll-bar: 0px;
  --scroll-bar-grid: 0px;
  --vh100: 100vh;
  --banner-height: 0px;
  --banner-displayed: 0;
}

$primaryRegular: '/fonts/ChronicleDisplay/ChronicleDisplay-Light.woff2';
$primaryBold: '/fonts/Degular/Degular-Bold.woff2';
$secondaryMedium: '/fonts/Degular/Degular-Semibold.woff2';
$secondaryRegular: '/fonts/Degular/Degular-Medium.woff2';

@font-face {
  font-family: 'Primary';
  src: url($primaryRegular) format('woff2');
  font-weight: theme($fontWeights, 'regular');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Primary';
  src: url($primaryBold) format('woff2');
  font-weight: theme($fontWeights, 'bold');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ChronicleDeck';
  src: url('/fonts/ChronicleDeck/ChronicleDeck-Roman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EmilyAustin';
  src: url('/fonts/EmilyAustin/EmilyAustin.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Secondary';
  src: url($secondaryMedium) format('woff2');
  font-weight: theme($fontWeights, 'medium');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Secondary';
  src: url($secondaryRegular) format('woff2');
  font-weight: theme($fontWeights, 'regular');
  font-style: normal;
  font-display: swap;
}

// this one is only used in product preview
@font-face {
  font-family: 'ChronicleDisp-XLight';
  src: url('/fonts/ChronicleDisplay/ChronicleDisplay-Light.woff2')
    format('woff2');
  font-style: normal;
  font-display: swap;
}

// this one is only used in product customization
@font-face {
  font-family: 'Recoleta-Black';
  src: url('https://api.bycharlot.akairos.fr/storage/assets/font/Recoleta-Black.ttf');
}

@font-face {
  font-family: 'SnellRoundhand';
  src: url('https://api.bycharlot.akairos.fr/storage/assets/font/SnellRoundhand.ttc');
}

html,
body {
  background-color: theme($colors, 'white');
  transition: background-color 0.8s linear;
  width: 100%;
  color: theme($colors, 'black');
  line-height: 1.4;
  overscroll-behavior-y: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

em {
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sb-show-main.sb-main-centered #root {
  padding: 0;
  box-sizing: border-box;
}

body {
  &.loading {
    cursor: wait;
  }

  &.preload * {
    transition: none !important;
  }
}

strong {
  font-weight: bold;
}

i {
  font-style: italic;
}

*,
a,
button,
input {
  &:focus {
    outline: theme($colors, 'black') solid thin;
    outline-offset: 0px;
  }

  *:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: theme($colors, 'black') solid thin;
    outline-offset: 0px;
  }
}
