@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.cta {
  /**
  * Brown Std
  */
  &-10-primary {
    text-transform: uppercase;
    @include font-base(12, 12, 'Secondary', 1.27, 0.05em, 'medium');
  }

  &-10-primary-bold {
    text-transform: uppercase;
    @include font-base(12, 12, 'Primary', 1.27, 0.05em, 'bold');
  }

  &-11-primary {
    @include font-base(13, 13, 'Secondary', 1.2, 0.05em, 'medium');
  }

  &-12-primary {
    text-transform: uppercase;
    @include font-base(14, 14, 'Secondary', 1.27, 0.05em, 'medium');
  }

  &-bch-12-secondary {
    text-transform: uppercase;
    @include font-base(12, 12, 'Secondary', 1, 0, 'medium');
  }

  &-12-primary-reg {
    @include font-base(12, 12, 'Secondary', 1.2, 0.05em, 'medium');
  }

  &-14-primary-reg {
    @include font-base(14, 14, 'Secondary', 1.2, 0.05em, 'medium');
  }

  &-18-primary {
    text-transform: uppercase;
    @include font-base(20, 20, 'Secondary', 1.2, 0.05em, 'medium');
  }

  /**
  * Source Code Pro
  */
  &-8-secondary {
    @include font-base(10, 10, 'Secondary', 1, 0.05em, 'medium');
  }
}
