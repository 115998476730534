@import 'styles/theme';

.BlurredCta {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: theme($spacings, 6) theme($spacings, 16) theme($spacings, 7);
  border-radius: theme($borderRadius, 'default');
  color: theme($colors, 'black');

  .spinner {
    width: theme($spacings, 16);
    height: theme($spacings, 16);
    margin-right: theme($spacings, 8);
  }

  &.isBig {
    padding: theme($spacings, 20) theme($spacings, 24);
  }

  @include set-transition(
    (
      'background': 'default',
    )
  );

  &.shade-1Theme {
    background-color: theme($colors, 'shade-3');
    color: theme($colors, 'black');

    @include from('md') {
      &.hovered,
      &:active {
        @include background-opacity(theme($colors, 'shade-1'), 0.3);
      }
    }
  }

  &.whiteTheme {
    background: theme($colors, 'white');
    color: theme($colors, 'black');
  }

  &.blackTheme {
    color: theme($colors, 'white');
    background: theme($colors, 'blue');
  }

  &.transparentTheme {
    background-color: transparent;
  }
}
