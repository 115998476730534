@import 'styles/theme';

.Input {
  position: relative;

  &.hidden {
    display: none;
  }
}

.input {
  width: 100%;
  border-radius: 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  background-clip: padding-box;

  @include set-transition(
    (
      'border': 'default',
    )
  );
}

.textarea {
  width: 100%;
  outline: none;
  border: none;
  box-sizing: border-box;
  resize: none;

  @include set-transition(
    (
      'border': 'default',
    )
  );
}
