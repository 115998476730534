@import 'styles/theme';

#onetrust-consent-sdk {
  font-family: theme($fontFamilies, 'Secondary') !important;
  color: theme($colors, 'black') !important;
  box-shadow: none !important;

  .ot-sdk-container {
    width: 100% !important;
  }

  .ot-pc-logo {
    display: none !important;
  }

  .ot-switch {
    width: 42px !important;
    height: 24px !important;

    .ot-switch-nob {
      background: transparent !important;
      border-radius: 42px !important;
      border: 1px solid theme($colors, 'black') !important;

      &:before {
        width: 20px !important;
        height: 20px !important;
        top: 1px !important;
        left: 1px !important;
        background-color: theme($colors, 'black') !important;
      }
    }
  }

  input:checked + .ot-switch .ot-switch-nob {
    border-color: theme($colors, 'white') !important;
    background-color: theme($colors, 'black') !important;

    &:before {
      background-color: theme($colors, 'black') !important;
      transform: translateX(18px) !important;
      background-color: theme($colors, 'white') !important;
    }
  }

  h2#ot-pc-title {
    margin: 0 !important;
    margin-bottom: theme($spacings, 8) !important;
  }

  h2,
  h3,
  .ot-cat-item,
  #onetrust-policy-text {
    font-family: theme($fontFamilies, 'Primary') !important;
    font-weight: theme($fontWeights, 'bold') !important;
    text-transform: uppercase !important;
    color: theme($colors, 'black') !important;
  }

  // Newly modified
  #onetrust-policy-title,
  #onetrust-policy-text {
    width: 100% !important;
  }

  #onetrust-policy-title {
    padding: 0 !important;
    margin-bottom: 8px !important;
  }

  #onetrust-policy-text,
  #onetrust-policy-text a {
    letter-spacing: 0.15em;
    line-height: 1.5 !important;
    margin-bottom: 0 !important;
    margin: 0 !important;
    text-transform: uppercase !important;
  }

  #onetrust-policy-text a {
    margin-left: 5px !important;
    color: theme($colors, 'black') !important;
  }

  .ot-cat-item p {
    font-size: theme($fontSizes, 10) !important;
    font-family: theme($fontFamilies, 'Secondary') !important;
  }

  #ot-pc-desc {
    font-size: theme($fontSizes, 10) !important;
  }

  #onetrust-pc-sdk {
    border-radius: 0px !important;
    // border: 1px solid theme($colors, 'black');
    @include from('md') {
      border-radius: theme($borderRadius, 'default') !important;
    }

    .category-menu-switch-handler {
      outline-offset: 0px !important;
    }

    .ot-pc-header,
    .ot-title-cntr {
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      box-sizing: border-box !important;
    }

    .ot-pc-header {
      border-bottom: 0;
    }

    .ot-close-cntr {
      float: unset !important;
    }

    .ot-tgl {
      input:focus {
        & + .ot-switch {
          .ot-switch-nob {
            &:before {
              outline: none !important;
            }
          }
        }
      }
    }
  }

  .ot-sdk-container {
    padding: 0px !important;
  }

  #onetrust-banner-sdk {
    box-sizing: border-box !important;
    padding: 0 !important;
    background: #fff !important;
    box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.12) !important;

    top: unset !important;
    bottom: 0 !important;
    width: 100% !important;
    transform: none !important;
    transform: translateX(-50%) !important;
    left: calc(50%) !important;

    @include from('md') {
      background: theme($colors, 'white') !important;
      max-width: 100% !important;
      right: theme($spacings, 24) !important;
      bottom: theme($spacings, 24) !important;
      border-radius: theme($borderRadius, 'default');
      max-width: calc(
        #{map-get($max, 'width')} - #{theme($spacings, 48)}
      ) !important;
      width: calc(100% - #{theme($spacings, 48)}) !important;
    }

    // @include from('lg') {
    //   width: 50% !important;
    // }

    // @include from('xxl') {
    //   width: 40% !important;
    // }

    // @include from('max-grid-width') {
    //   width: 30% !important;
    // }

    #onetrust-policy {
      padding: 0 !important;
      margin: 0 !important;
    }

    button {
      background-color: transparent !important;
    }

    &:focus {
      outline: none !important;
    }

    .ot-sdk-row {
      display: flex !important;
      flex-direction: column !important;
    }

    #onetrust-accept-btn-handler {
      @include from('md') {
        margin: 0 !important;
      }
    }

    #onetrust-group-container {
      width: 100%;
      padding: theme($spacings, 24) theme($spacings, 24) theme($spacings, 40)
        theme($spacings, 24) !important;
      background: rgba(theme($colors, 'shade-1'), 0.25) !important;

      @include from('md') {
        padding: theme($spacings, 48) theme($spacings, 24) theme($spacings, 56)
          theme($spacings, 24) !important;
      }
    }

    #onetrust-button-group-parent {
      position: relative !important;

      padding: {
        top: 0 !important;
        left: theme($spacings, 24) !important;
        right: theme($spacings, 24) !important;
        bottom: theme($spacings, 24) !important;
      }
      left: unset !important;
      top: unset !important;
      transform: none !important;
      background: rgba(theme($colors, 'shade-1'), 0.25) !important;
      width: 100% !important;
      margin: unset !important;
    }

    #onetrust-button-group {
      display: flex !important;
      flex-direction: column !important;
      margin-right: 0 !important;
      width: 100% !important;

      @include from('md') {
        margin-top: 0 !important;
        flex-direction: row !important;

        .banner-actions-container {
          display: flex !important;
          max-width: 100% !important;
          width: 100% !important;
          flex: 1;
        }
      }
    }
  }

  p,
  strong {
    font-size: 10px !important;
    color: inherit !important;
    font-weight: theme($fontWeights, 'regular') !important;
  }

  strong {
    font-family: inherit !important;
    font-weight: theme($fontWeights, 'medium') !important;
  }

  button#accept-recommended-btn-handler,
  button#onetrust-reject-all-handler,
  button#onetrust-accept-btn-handler,
  button#onetrust-pc-btn-handler,
  button.save-preference-btn-handler,
  button.ot-pc-refuse-all-handler {
    font-family: theme($fontFamilies, 'Primary') !important;
    font-weight: theme($fontWeights, 'bold') !important;

    padding: theme($spacings, 20) theme($spacings, 16) !important;
    border-radius: theme($borderRadius, 'default');

    line-height: 1.6 !important;
    font-size: 12px !important;
    letter-spacing: 0.2em !important;

    text-transform: uppercase !important;
    box-sizing: border-box !important;
    transition: background-color 0.2s linear, color 0.2s linear,
      border-color 0.2s linear;

    border: none !important;
    background: theme($colors, 'black') !important;

    &:focus {
      outline: none !important;
    }

    &:hover {
      opacity: 1;
    }

    @include from('md') {
      padding: theme($spacings, 24) theme($spacings, 16) !important;
      line-height: 1.2 !important;
    }
  }

  button#onetrust-reject-all-handler,
  button#onetrust-pc-btn-handler,
  button#onetrust-accept-btn-handler {
    margin-bottom: theme($spacings, 8) !important;
    margin-right: 0 !important;

    @include from('md') {
      border-radius: 0px !important;
      flex: 1 !important;
      margin-top: 0;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }

  button#onetrust-reject-all-handler,
  button#onetrust-pc-btn-handler {
    @include from('md') {
      margin-right: 1px !important;
    }
  }

  button#onetrust-pc-btn-handler {
    color: theme($colors, 'black') !important;
    background: rgba(theme($colors, 'shade-1'), 0.1) !important;

    @include from('md') {
      background: rgba(theme($colors, 'white'), 0.1) !important;
      max-width: 50% !important;
    }
  }

  // Override
  button#onetrust-reject-all-handler {
    background: transparent !important;
    color: theme($colors, 'black') !important;
    padding: 0 !important;
    position: absolute !important;
    width: auto !important;
    flex: auto !important;
    min-width: 0 !important;
    border-bottom: 1px solid theme($colors, 'black') !important;

    line-height: 1.5 !important;
    font-size: 10px !important;
    letter-spacing: 0.15em !important;
    border-radius: 0 !important;

    bottom: calc(100% + 14px) !important;
    left: theme($spacings, 24) !important;

    @include from('md') {
      bottom: calc(100% + #{theme($spacings, 40)}) !important;
    }
  }

  button#onetrust-accept-btn-handler,
  button#onetrust-pc-btn-handler {
    border-radius: theme($borderRadius, 'default') !important;
  }

  button#onetrust-accept-btn-handler {
    border: none !important;
    color: theme($colors, 'white') !important;
    background: #191b4a !important;
    display: block;
    width: 100% !important;
  }

  button.cookie-setting-link,
  button.ot-link-btn {
    font-family: theme($fontFamilies, 'Primary') !important;
    font-weight: theme($fontWeights, 'regular') !important;
    color: theme($colors, 'black') !important;
    line-height: 1 !important;
    font-size: 12px !important;

    display: inline-block !important;
    padding: 0 !important;
    margin: 0px !important;
    margin-top: 9px !important;
    border: none !important;
    letter-spacing: auto !important;
    background-color: transparent !important;

    &:focus {
      outline: none !important;
    }

    &:hover {
      box-shadow: unset !important;
      opacity: 1 !important;
    }
  }

  .ot-fade-in,
  .ot-fade-out,
  #onetrust-banner-sdk {
    transition-duration: 0ms !important;
    animation-duration: 0ms !important;
  }

  button.category-host-list-handler {
    color: theme($colors, 'black') !important;
    text-decoration: underline !important;
  }

  #onetrust-close-btn-container {
    display: none;
    // display: inline-flex;
    // top: theme($spacings, 16) !important;
    // right: theme($spacings, 16) !important;
    // background: rgba(theme($colors, 'shade-1'), 0.1) !important;
    // border-radius: theme($spacings, 8) !important;
    // transform: none !important;

    // @include from('md') {
    //   top: theme($spacings, 24) !important;
    //   right: theme($spacings, 24) !important;
    // }

    // button {
    //   margin: 0 !important;
    //   top: 0 !important;
    //   right: 0 !important;
    //   width: 32px !important;
    //   height: 32px !important;
    //   background-size: 32px;
    //   background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDEyTDEyIDIwTTIwIDIwTDEyIDEyIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+');
    // }
  }

  #ot-sdk-btn-floating {
    display: none !important;

    // &.ot-floating-button {
    //   left: 10px;
    //   bottom: 10px;
    // }
    // .ot-floating-button__front,
    // .ot-floating-button__back {
    //   box-shadow: none;
    // }

    // .ot-floating-button__back {
    //   background: white;

    //   .ot-floating-button__close {
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;
    //   }

    //   svg {
    //     width: 16px;
    //     height: 16px;

    //     polygon {
    //       fill: black;
    //     }
    //   }
    // }
  }
}
