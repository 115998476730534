@import 'styles/theme';

.AccountButton {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: theme($colors, 'error-compare-at-price');
    width: 6px;
    height: 6px;
    transform: translate3d(-60%, 60%, 0px) scale(0);
    display: block;
    border-radius: 50%;

    @include set-transition(
      (
        'transform': 'reduced',
      )
    );
  }

  .icon {
    svg {
      path {
        fill: none;
      }
    }
  }

  &.withArticles {
    &::after {
      transform: translate3d(-60%, 60%, 0px) scale(1);
    }
  }
}
