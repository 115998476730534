@use 'sass:math';

@import './mixins';
/*
  COLORS
*/
$colors: (
  'white': #ffffff,
  'black': #000000,
  'shade-1': #b5c9c0,
  'blue': #191b4a,
  'shade-2': #708983,
  'shade-3': #f5f5f5,
  'error-compare-at-price': #bc2f26,
  'shade-4': #f2f0ea,
  'shade-5': #f2f2f2,
  'shade-6': #808080,
  'green': #339749,
  'cream': #f2f0ea,
  'beige': #f7f8f3,
  'gray-1': #737373,
  'gray-2': #b3b3b3,
  'gray-3': #e0e0e0,
  'gray-4': #f7f7f7,
);

/*
  GRID
*/
$mobile: (
  'columns': 6,
  // Those two values might need a clamp or a minmax
  'gap': clamp(16px, 4vw, 32px),
  'gutter': clamp(16px, 4vw, 32px),
);

$desktop: (
  'columns': 12,
  'gap': 24px,
  'gutter': 24px,
);

$max: (
  'width-design': 1440px,
  'width': 1940px,
);

/*
  FONTS
*/
$Primary: 'Primary', sans-serif;
$Secondary: 'Secondary', serif;

$fontFamilies: (
  'Primary': $Primary,
  'Secondary': $Secondary,
);

$fontSizes: (
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  25: 25px,
  26: 26px,
  27: 27px,
  28: 28px,
  30: 30px,
  35: 35px,
  36: 36px,
  40: 40px,
  48: 48px,
  60: 60px,
  68: 68px,
  80: 80px,
  90: 90px,
  96: 96px,
  190: 190px,
);

$fluidFontSizes: (
  12: 12px,
  14: 14px,
  16: 16px,
  24: 24px,
  25: 25px,
  36: 36px,
  40: 40px,
  44: 44px,
  60: 60px,
  68: 68px,
  96: 96px,
  190: 190px,
);

$fontWeights: (
  'regular': 400,
  'medium': 500,
  'bold': 700,
);

// MARGIN AND PADDING
$spacings: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  10: 10px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  25: 25px,
  28: 28px,
  30: 30px,
  32: 32px,
  35: 35px,
  40: 40px,
  42: 42px,
  45: 45px,
  48: 48px,
  50: 50px,
  52: 52px,
  55: 55px,
  56: 56px,
  60: 60px,
  64: 64px,
  65: 65px,
  70: 70px,
  72: 72px,
  75: 75px,
  80: 80px,
  88: 88px,
  90: 90px,
  100: 100px,
  120: 120px,
  135: 135px,
  140: 140px,
  150: 150px,
  175: 175px,
  180: 180px,
  200: 200px,
  250: 250px,
  325: 325px,
);

/*
  SCREENS
*/
$screens: (
  xxxs: 375px,
  xxs: 480px,
  xs: 576px,
  sm: 640px,
  md: 768px,
  l: 930px,
  lg: 1024px,
  xl: 1280px,
  xxl: map-get($max, 'width-design'),
  'max-grid-width': map-get($max, 'width'),
);

/*
  RATIO
*/
$ratio: (
  '2/3': math.div(2, 3),
);

@function getBannerHeight($height) {
  @return calc(#{$height} + (var(--banner-height) * var(--banner-displayed)));
}

$navHeight: (
  'mobile': 64px,
  'mobileWithBanner': getBannerHeight(64px),
  'desktop': 67px,
  'desktopWithBanner': getBannerHeight(67px),
  'desktopBanner': 35px,
  'mobileBanner': 35px,
);

$easings: (
  'ease-in-out': cubic-bezier(0.56, 0.21, 0.48, 0.85),
);

$borderRadius: (
  'small': 0px,
  'default': 2px,
);

$shadows: (
  'lighter': 0px 0px 10px 0px rgba(map-get($colors, 'black'), 0.1),
  'default': 0px 14px 54px 0px rgba(map-get($colors, 'black'), 0.1),
  'small': 3px 3px 6px 0px rgba(map-get($colors, 'black'), 0.2),
  'light-images': 0px 3px 14px 0px rgba(map-get($colors, 'black'), 0.1),
  'nav-push': 0px -10px 54px 0px rgba(map-get($colors, 'black'), 0.1),
);

$transitions: (
  'reduced': 0.1s ease,
  'opacity': 0.12s linear,
  'default': 0.2s ease-in-out,
  'longer': 0.4s ease-in-out,
);

$stickyAddToCartHeight: 56px;
