@import 'styles/theme';

.ImageWithCaption {
  display: flex;
  flex-direction: column;
  text-align: center;

  .image {
    position: relative;
    height: auto;
    border-radius: theme($borderRadius, 'default');
    overflow: hidden;
  }

  .caption {
    margin-top: theme($spacings, 16);
  }
}
