@import 'styles/theme';
.Input {
  position: relative;

  .input {
    box-sizing: border-box;
    width: 100%;

    padding: theme($spacings, 28) theme($spacings, 20);
    border-radius: theme($borderRadius, 'default');
    @include background-opacity(theme($colors, 'shade-1'), 0.1);

    &::placeholder {
      color: currentColor;
      text-transform: uppercase;
    }

    &.error,
    &:focus,
    &:not(:placeholder-shown),
    &:not([data-value='']):not([value='']):valid {
      & ~ label {
        transform: translateY(-150%) scale(0.75);
        color: theme($colors, 'shade-2');
      }
    }

    &.error {
      @include background-opacity(
        theme($colors, 'error-compare-at-price'),
        0.2
      );

      &:focus ~ label,
      &:not(:placeholder-shown) ~ label,
      &:not([value='']):valid ~ label,
      &:not([data-value='']):valid ~ label,
      & ~ label {
        color: rgba(theme($colors, 'error-compare-at-price'), 0.5);
      }
    }
  }

  label {
    position: absolute;
    left: theme($spacings, 20);
    pointer-events: none;
    color: theme($colors, 'black');
    transform-origin: center left;
    top: 50%;
    transform: translateY(-50%);

    @include set-transition(
      (
        'all': 'default',
      )
    );
  }

  &.textArea {
    @include background-opacity(theme($colors, 'shade-1'), 0.1);

    // This won't work on firefox for now, but onblur wont trigger either
    &:has(:focus) {
      outline: theme($colors, 'black') solid thin;
      outline-offset: 0px;
    }

    .error {
      @include background-opacity(
        theme($colors, 'error-compare-at-price'),
        0.1
      );
    }

    textarea {
      background-color: transparent;
    }

    label {
      top: 40px;
    }

    .errorList {
      position: absolute;
      right: 22px;
      top: theme($spacings, 6);
      transform: none;
    }
  }

  &:not(.textArea) {
  }

  .errorList {
    position: absolute;
    right: theme($spacings, 20);
    top: 50%;
    transform: translateY(-50%);
  }

  &.birthDate {
    .input {
      height: 100%;
      padding: theme($spacings, 10) theme($spacings, 20);
    }
  }

  input[type='date'] {
    position: relative;
  }

  // trick to keep date input with calender at the same height as text input
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    right: theme($spacings, 20);
    width: 12px;
    padding: 0px;
    margin: 0px;
  }
}
