@import 'styles/theme';

.TagCustomizable {
  display: inline-flex;
  align-items: center;

  .label {
    display: flex;
    align-items: center;
  }

  .wheelIcon {
    height: 10px;
    width: 10px;
    border-radius: theme($spacings, 4);
    margin-right: 4px;
    margin-top: -1px;

    background: conic-gradient(
      from 180deg at 50% 50%,
      #64f473 0deg,
      #fea000 71.25deg,
      #fc4a3e 140.63deg,
      #ba5dd2 223.13deg,
      #0899f4 285deg,
      #3ec016 360deg
    );
  }
}
