@import 'styles/theme';

.Line {
  position: relative;
  display: block;
  height: 1px;
  width: 100%;
  z-index: 2;
  display: block;
  transform: translate3d(-100%, 0, 0);
  background-color: theme($colors, 'black');

  &.theme-gray-1 {
    background-color: theme($colors, 'gray-1');
  }

  &.theme-shade-1 {
    background-color: theme($colors, 'shade-1');
  }

  &.theme-gray-2 {
    background-color: theme($colors, 'gray-2');
  }
}
