@import 'styles/theme';

.Page {
  position: relative;
  min-height: calc(85vh - theme($navHeight, 'mobile'));
  padding-top: theme($navHeight, 'mobile');

  @include from('md') {
    padding-top: theme($navHeight, 'desktop');
    min-height: calc(85vh - theme($navHeight, 'desktop'));
  }

  &.forcedMarginFromBanner {
    padding-top: calc(
      theme($navHeight, 'desktopBanner') + theme($navHeight, 'mobile')
    );
    @include from('md') {
      padding-top: calc(
        theme($navHeight, 'desktopBanner') + theme($navHeight, 'desktop')
      );
    }
  }

  &.withoutMarginTop {
    padding-top: 0;
  }
}
