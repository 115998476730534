@import 'styles/theme';

$bottomMobileOffset: calc(100vh - vh(100));

.NavigationMobile {
  display: flex;
  justify-content: space-between;
  padding-top: theme($spacings, 16);
  padding-bottom: theme($spacings, 16);
  will-change: transform, background;

  @include set-transition(
    (
      'transform': 'default',
      'background': 'default',
    )
  );

  .whiteBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'white');
    opacity: 0;
    box-shadow: theme($shadows, 'lighter');
    @include set-transition(
      (
        'opacity': 'opacity',
      )
    );
  }

  &.hasScrolled {
    .whiteBackground {
      opacity: 1;
    }
  }

  &.isOpen {
    .whiteBackground {
      box-shadow: none;
      opacity: 1;
    }
  }

  .top {
    position: relative;
    display: flex;
    align-items: center;
    grid-column: 1/-1;

    .topLeft,
    .topCenter,
    .topRight {
      position: relative;
      display: inline-flex;
    }

    .topLeft,
    .topRight {
      width: theme($spacings, 32);
    }

    .topRight,
    .topCenter {
      margin-left: auto;
    }
  }

  .burgerButton {
    margin-left: -8px;
  }

  .closeButton {
    margin-left: 0;
    width: 14px;
    > div {
      width: 100%;
    }
  }

  .accountButton {
    position: absolute;
    left: -2.5 * theme($spacings, 24);
    top: 0;
  }

  .searchButton {
    position: absolute;
    left: -1 * theme($spacings, 24);
    top: 0;
  }

  .cartButton {
    right: -16px;
  }

  .menuTrigger {
    padding-bottom: theme($spacings, 4);
    cursor: pointer;
  }

  .panel {
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    background-color: theme($colors, 'white');

    &.withBanner {
      height: calc(stableVh(100) - theme($navHeight, 'desktopBanner'));

      .panelContent {
        height: calc(100vh - theme($navHeight, 'desktopBanner'));
        padding-top: calc(theme($spacings, 16) + map-get($navHeight, 'mobile'));
      }
    }

    &.isOpen {
      align-items: flex-end;
    }
  }

  .panelContent {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    height: calc(100vh - map-get($navHeight, 'mobile'));
    padding-top: theme($spacings, 16);
    padding-bottom: $bottomMobileOffset;
    box-sizing: border-box;
    margin-top: auto;
    overflow-y: auto;
    align-items: center;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .gridRow {
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
  }

  .firstLevelLinks {
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: theme($spacings, 64);
  }

  .bottomFirstLevel {
    display: flex;
    align-items: center;
    justify-content: center;

    .bottomFirstLevelLinks {
      grid-column: 1/-1;
      display: flex;
      justify-content: space-between;
    }

    .bottomItem {
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .selectItem {
      padding-top: theme($spacings, 16);
      padding-bottom: theme($spacings, 16);
      padding-left: 0;
      padding-right: 0;
    }
  }

  .columnTitle {
    margin-left: theme($spacings, 16);
    margin-bottom: theme($spacings, 8);
  }

  .columnLink {
    text-align: left;
  }

  .subPanel {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .panelBack {
    display: flex;
    flex-direction: row;
    border-radius: theme($borderRadius, 'default');
    margin-bottom: theme($spacings, 16);
    overflow: hidden;
  }

  .buttonBack {
    display: inline-flex;
    flex: 1;
    height: 100%;

    box-sizing: border-box;
    min-height: theme($spacings, 32);
    align-items: center;
    justify-content: center;
    @include background-opacity(theme($colors, 'shade-1'), 0.1);

    &:not(.back) {
      padding: 10px 20px;
    }

    &.back {
      @include background-opacity(theme($colors, 'shade-1'), 0.2);
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  .NavigationSubPanel {
    flex: 1;
    display: flex;
    flex-direction: column;

    .secondLevelLinks {
      margin-bottom: theme($spacings, 32);
    }

    .secondLevelLinks,
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .column {
      & + .column {
        margin-top: theme($spacings, 32);
      }
    }

    .seeAll {
      align-self: flex-start;
      margin-bottom: theme($spacings, 16);
    }

    .bottomPush {
      margin-top: auto;
      box-shadow: theme($shadows, 'nav-push');
      backdrop-filter: blur(50px);
    }
  }

  // Old below
  .listLink {
    margin-top: theme($spacings, 5);
  }

  .links {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    max-height: calc(100vh - map-get($navHeight, 'mobile') * 2);
    overflow-y: auto;
    margin-top: theme($spacings, 40);
    align-items: center;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .accountLink {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate3d(-50%, 0, 0);
  }
}
