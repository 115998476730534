@use 'sass:math';
@import 'styles/theme';

.NavigationDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  will-change: transform;

  @include set-transition(
    (
      'transform': 'default',
    )
  );

  .backgroundTopBar {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center top;
  }

  .topContainer {
    pointer-events: all;
    width: 100%;
  }

  .navigationTop {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    @include from('max-grid-width') {
      width: 1940px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .leftContent,
  .rightContent {
    margin-left: 24px;
    margin-right: 24px;
  }

  .leftContent,
  .centerContent,
  .rightContent {
    display: flex;
    align-items: center;
  }

  .search {
    & + .cart {
      margin-left: theme($spacings, 10);
    }
  }

  .centerContent {
    width: 100%;
    justify-content: center;
  }

  .linksContainer {
    padding-top: theme($spacings, 16);
    padding-bottom: theme($spacings, 16);
    display: flex;
    justify-content: center;

    .link {
      margin-right: 0px;
      margin-left: 0;
      text-align: center;

      &.withoutLink {
        &:hover {
          cursor: auto;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // States
  &.hasLongNavItems {
    @include below('xxl') {
      .linksContainer {
        .link {
          padding-left: theme($spacings, 8);
          padding-right: theme($spacings, 8);
        }
      }
    }

    &.hasScrolled {
      .whiteBackground {
        box-shadow: theme($shadows, 'lighter');
        background-color: theme($colors, 'white');
      }
    }
  }

  .whiteBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.2s linear;
    opacity: 1;
  }

  .panelContainer {
    display: flex;
  }

  .NavigationPanel {
    z-index: 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw - var(--scroll-bar));
    display: flex;

    .panelWrapper {
      left: 50%;
      position: absolute;
      display: flex;
      transform: translate3d(-50%, 0, 0);
      justify-content: center;
    }

    .panelContent {
      pointer-events: all;
      display: flex;
      position: relative;
      justify-content: flex-start;
      padding: theme($spacings, 24);
      padding-top: 40px;
    }

    .background {
      position: absolute;
      box-shadow: theme($shadows, 'lighter');
      border-radius: 8px;
      left: 0;
      top: 16px;
      width: 100%;
      height: calc(100% - 16px);
      background-color: theme($colors, 'white');
      z-index: -1;
    }

    .panelLinks {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .seeAll {
        padding: 10px 30px;
        margin-top: auto;
      }
    }

    &.enableSeeAll {
      .panelContent {
        min-height: 122px;
      }

      .columns {
        margin-bottom: theme($spacings, 32);
      }
    }

    .columns {
      display: flex;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      min-width: 145px;
      max-width: 185px;
      box-sizing: content-box;

      .columnTitle {
        letter-spacing: 0.1em;
      }

      .columnTitle + a {
        margin-top: 24px;
      }

      a {
        &:not(.seeAll) {
          padding: 0;
        }
      }

      & + .column {
        padding-left: theme($spacings, 70);
      }

      .columnLink {
        font-weight: 300;

        &:hover {
          color: theme($colors, 'shade-2');
        }
        & + .columnLink {
          margin-top: theme($spacings, 8);
        }
      }
    }

    // States
    &.hasPush {
      .panelLinks {
        + .columnPush {
          padding-left: theme($spacings, 70);
        }
      }

      .columnPush {
        > div {
          height: 100%;
          max-height: 200px;
        }

        &.imageOnly {
          width: 120px;
        }
      }
    }
  }
}
