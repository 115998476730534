@import 'styles/theme';

.NavBanner {
  pointer-events: auto;
  position: relative;
  background-color: theme($colors, 'shade-3');
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
  padding: 5px 0;
  width: vw(100);
  height: theme($navHeight, 'desktopBanner');
  box-sizing: border-box;

  .grid {
    position: relative;
    height: 100%;
  }

  .slider {
    grid-column: 1 / span 6;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    @include from('md') {
      grid-column: 1 / span 12;
    }
  }

  .textWrapper {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    box-sizing: border-box;

    @include from('md') {
      padding: 0 24px;
    }
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }

  .closeButton {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 101;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    right: 16px;

    @include from('md') {
      right: 24px;
    }

    .icon {
      width: 14px;
      height: 14px;
    }
  }
}
