@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.text {
  /**
  * Source Code Pro
  */
  &-10-primary {
    @include font-base(12, 12, 'Primary', 1.8em, 0em, 'regular');
  }
  &-10-secondary {
    @include font-base(12, 12, 'Secondary', 1.8em, 0em, 'regular');
  }
  &-12-secondary {
    @include font-base(14, 14, 'Secondary', 1.8em, 0em, 'regular');
  }
  &-16-secondary {
    @include font-base(18, 18, 'Secondary', 1.8em, 0em, 'regular');
  }
  &-18-secondary {
    @include font-base(18, 18, 'Secondary', 1.4, 0em, 'regular');
  }
}
