@import 'styles/theme';

.NavigationButtonIcon {
  display: inline-flex;
  align-items: center;

  .childrenContent {
    display: flex;
  }

  &.blackTheme {
    g {
      stroke: theme($colors, 'black');
    }
    path {
      fill: theme($colors, 'black');
    }
  }

  &.whiteTheme {
    g {
      stroke: theme($colors, 'white');
    }
    path {
      fill: theme($colors, 'white');
    }
  }

  &.axolotlTheme {
    path {
      fill: theme($colors, 'axolotl');
    }
  }
}
