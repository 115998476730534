@import 'styles/theme';

.LangSwitcher {
  position: relative;
  min-width: 135px;

  > div {
    width: 100%;
  }

  .select {
    width: 100%;
    border: none;
    appearance: none;
    display: block;
    text-align: center;
    background-repeat: no-repeat;
    background-position-x: 0%;
    background-position-y: 50%;
    padding-right: theme($spacings, 32);
    padding-top: theme($spacings, 6);
    padding-bottom: theme($spacings, 6);
    padding-left: theme($spacings, 16);
    background: transparent;
  }

  .arrowIcon {
    pointer-events: none;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);

    path {
      stroke: currentColor;
    }
  }

  .icon {
    border: 1px solid theme($colors, 'gray-2');
  }
}
