@import 'styles/theme';

.AlertCenter {
  position: fixed;
  z-index: 1000;

  div + div {
    margin-top: theme($spacings, 16);
  }

  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'right',
      'gutters': 1,
      'gaps': 0,
      'columns': 0,
    )
  );
  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'bottom',
      'gutters': 1,
      'gaps': 0,
      'columns': 0,
    )
  );

  @include from('md') {
    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'right',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );
    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'bottom',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );
  }
}

.Alert {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 300px;
  user-select: none;
  min-height: 64px;
  pointer-events: auto;
  background-color: theme($colors, 'white');
  border-radius: theme($borderRadius, 'default');
  padding: theme($spacings, 24);
  box-shadow: theme($shadows, 'default');

  &.success,
  &.error {
    background-color: theme($colors, 'white');
  }

  .title {
    line-height: 1;
  }
}

.title,
.message {
  margin-bottom: theme($spacings, 16);

  &:last-child {
    margin-bottom: 0;
  }
}

.CloseIcon {
  cursor: pointer;
  padding: theme($spacings, 12);
  margin: -#{theme($spacings, 12)};
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: theme($spacings, 24);
  right: theme($spacings, 24);
}
