@import 'styles/theme';

.Icon {
  path {
    @include set-transition(
      (
        'fill': 'default',
      )
    );
  }
}

.ArrowLeftIcon {
  transform: rotateZ(180deg);
}

.whiteTheme path {
  fill: theme($colors, 'white');
}

.blackTheme path {
  fill: theme($colors, 'black');
}

.Search {
  width: 13px;
}
