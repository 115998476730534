@import 'styles/theme';

.Select {
  position: relative;

  .icon {
    position: absolute;
    line-height: 0;
    transform: translateY(-50%) rotate(90deg);
    transform-origin: center center;
    top: 50%;
    right: 0;
    pointer-events: none;
  }
}

.select {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 0%;
  background-position-y: 50%;
  padding-right: theme($spacings, 32);
  padding-top: theme($spacings, 6);
  padding-bottom: theme($spacings, 6);
  padding-left: theme($spacings, 16);
  appearance: none;
  border: none;
  box-sizing: border-box;

  @include set-transition(
    (
      'border': 'default',
    )
  );
}
