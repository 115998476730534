@import 'styles/theme';

.BackToTop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;

  .button {
    margin-right: theme($spacings, 16);
    margin-bottom: theme($spacings, 16);
    background: theme($colors, 'white');

    border: 1px solid rgba(theme($colors, 'shade-1'), 0.2);
    border-radius: theme($borderRadius, 'small');
    display: inline-flex;

    @include from('md') {
      margin-right: theme($spacings, 24);
      margin-bottom: theme($spacings, 24);
    }
  }

  .icon {
    transform: rotate(-90deg);
  }
}
