@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.title {
  /**
  * Brown Std
  */
  &-bch-12-primary {
    @include font-base(12, 12, 'Primary', 1.16, 0, 'bold');
  }
  &-bch-12-secondary {
    @include font-base(12, 12, 'Secondary', 1.16, 0, 'regular');
  }
  &-bch-14-primary {
    @include font-base(14, 14, 'Secondary', 1.16, 0, 'regular');
  }
  &-bch-18-primary {
    @include font-base(18, 18, 'Primary', 1.16, 0, 'bold');
  }
  &-bch-26-primary {
    @include font-base(26, 26, 'Primary', 1.16, 0, 'regular');
  }
  &-bch-26-36-primary {
    @include font-base(26, 36, 'Primary', 1.16, 0, 'regular');
  }
  &-bch-48-96-primary {
    @include font-base(48, 96, 'Primary', 1.16, 0, 'regular');
  }
  &-10-primary {
    @include font-base(14, 14, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-12-primary {
    @include font-base(14, 14, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-14-primary {
    @include font-base(16, 16, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-26-primary {
    @include font-base(26, 26, 'Primary', 1.4, 0, 'regular');
  }

  &-16-primary {
    @include font-base(18, 18, 'Primary', 1.27, 0.1em, 'bold');
  }

  &-12-18-primary {
    @include font-base(14, 20, 'Primary', 1.27, 0.1em, 'bold');
  }
  &-18-primary {
    @include font-base(36, 36, 'Primary', 1.16, 0, 'regular');
  }

  &-22-primary {
    @include font-base(22, 22, 'Primary', 1.16, 0.1em, 'bold');
  }
  &-16-primary {
    @include font-base(18, 18, 'Primary', 1.16, 0, 'bold');
  }
  &-18-24-primary {
    @include font-base(36, 48, 'Primary', 1.16, 0, 'regular');
  }
  &-24-28-primary {
    @include font-base(24, 28, 'Primary', 1.16, 0, 'regular');
  }
  &-28-primary {
    @include font-base(28, 28, 'Primary', 1.16, 0, 'regular');
  }
  &-26-36-primary {
    @include font-base(25, 36, 'Primary', 1.16, 0, 'regular');
  }
  &-fluid-24-44-primary {
    @include font-base(36, 48, 'Primary', 1.16, 0, 'regular');
  }

  &-fluid-68-96-primary {
    @include font-base(68, 96, 'Primary', 1.16, 0, 'regular');
  }
}
