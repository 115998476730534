@import 'styles/theme';

.isHidden {
  display: none;
}

.submitButton {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: theme($spacings, 35);

  .submit {
    width: 100%;
  }

  .spinner {
    width: theme($spacings, 24);
    height: theme($spacings, 24);
  }
}

.result {
  text-align: center;
}

.resultTitle {
  display: block;
  margin-bottom: theme($spacings, 16);
}

.layout-default {
  .gdpr {
    margin-top: theme($spacings, 35);
    width: calc(100% - theme($spacings, 40));
    margin-left: theme($spacings, 20);
  }
}

.layout-small {
  .inputContainer {
    position: relative;

    .input {
      padding-top: theme($spacings, 25);
      padding-bottom: theme($spacings, 25);
    }
  }
  .submitButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 0;
    width: auto;

    .submit {
      height: 100%;
    }
  }

  .gdpr {
    margin-top: theme($spacings, 12);
  }
}
