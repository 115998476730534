@import 'styles/theme';

.NavItemCta {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: theme($spacings, 8) theme($spacings, 16);
  border-radius: theme($borderRadius, 'default');
  color: theme($colors, 'gray-2');

  &.isActive {
    color: theme($colors, 'black');
  }

  &.isCentered {
    justify-content: center;
  }

  &.withBackground {
    @include set-transition(
      (
        'background': 'default',
        'color': 'default',
      )
    );

    &.shade-1Theme {
      color: theme($colors, 'black');

      @include from('md') {
        &.hovered,
        &.isActive,
        &:active {
          @include background-opacity(theme($colors, 'shade-1'), 0.2);
        }
      }
    }

    &.white-on-shade-1Theme {
      color: theme($colors, 'white');

      @include from('md') {
        &.hovered,
        &.isActive,
        &:active {
          @include background-opacity(theme($colors, 'shade-1'), 0.1);
        }
      }
    }
  }
}
