@import 'styles/theme';

.Breadcrumb {
  // align-items: center;
  // justify-content: flex-start;
  // flex-flow: row wrap;
  // display: flex;
  line-height: 1;

  .separator {
    display: inline;
    content: '/';
    margin-right: theme($spacings, 5);
  }

  .item {
    // margin-bottom: 4px;
    flex-shrink: 0;
    display: inline;
    align-items: center;
    font-size: 10px;
    box-sizing: border-box;
    border-radius: theme($borderRadius, 'default');
    margin-right: theme($spacings, 5);
    text-transform: uppercase;
    max-width: 100%;

    * {
      display: inline;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}
